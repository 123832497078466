@use "../../../../master.scss";

$user-list-mobile-break: 400px;
$user-list-toosmall-break: 270px;

.access-modal {
  --action-button-padding: 8px;
  margin: 30px 0 !important;
  max-width: calc(100% - 60px);
  .ant-modal-content {
    padding: 50px 55px 60px;

    .ant-modal-close {
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      &-icon {
        font-size: 18px;
      }
      @include master.media-smalldevice {
        right: 10px;
        top: 10px;
        width: 25px;
        height: 25px;
        &-icon {
          font-size: 12px;
        }
      }
    }
    .top-header {
      margin-bottom: 25px;
      padding-bottom: 20px;
      border-bottom: 1px solid master.$grey;

      .title-container {
        @include master.flex-between();
        flex-flow: row wrap;
        .view-all-users {
          background: transparent;
          border: none;
          color: master.$theme-color;
          border-bottom: 1px solid master.$theme-color;
          margin-left: auto;
          cursor: pointer;
          @include master.media-smalldevice {
            font-size: 13px;
            margin: 5px auto 5px 15px;
          }
        }
      }

      .title {
        font-size: 28px;
        font-family: master.$font-arial-bold;

        &:not(.bar-left) {
          padding-left: 20px;
          @include master.media-smalldevice {
            padding-left: 15px;
          }
          &::before {
            content: "";
            position: absolute;
            width: 8px;
            height: calc(100% - 14px);
            top: 7px;
            left: 0;
            background: master.$theme-color-primary;
            @include master.media-smalldevice {
              width: 6px;
            }
          }
        }
        .back-btn {
          font-size: 20px;
          margin-right: 20px;
          cursor: pointer;
          @include master.media-smalldevice {
            font-size: 16px;
            margin-right: 10px;
          }
        }

        @include master.media-smalldevice {
          width: 100%;
          font-size: 18px;
        }
      }
      .description {
        font-size: 15px;
        padding-left: 20px;
        @include master.media-smalldevice {
          position: unset;
          margin-top: 5px;
          font-size: 13px;
          padding-left: 15px;
        }
      }
      &.access-list {
        padding-bottom: 0;
        border: none;
      }
      @include master.media-smalldevice {
        margin-bottom: 15px;
        padding-bottom: 10px;
      }
    }
    .invite-box {
      display: flex;
      margin-bottom: 30px;
      gap: 15px;
      .ant-form-item {
        margin: 0;
        width: 100%;
        .ant-input {
          padding: 9px 15px;
          @include master.media-smalldevice {
            padding: 5px 10px;
            font-size: 13px;
          }
        }
      }
      button {
        background: master.$light-grey;
        color: master.$black;
        border: none;
        border-radius: 50px;
        height: 42px;
        padding: 7px 37px;
        width: 150px;
        font-family: master.$font-arial-bold;
        font-size: 15px;
        min-width: auto;
        &:hover {
          background: master.$grey;
        }
        &:disabled {
          background: master.$light-grey !important;
          opacity: 0.8;
        }
        @include master.media-smalldevice {
          padding: 5px;
          font-size: 14px;
          width: 120px;
          height: 34px;
        }
      }
    }
    .upload-list {
      display: inline-block;
      background: transparent;
      border: none;
      border-bottom: 1px solid master.$theme-color;
      margin: 15px 0 30px;
      font-size: 14px;
      color: master.$theme-color;
    }
    h4 {
      margin: 0;
      line-height: 20px;
      word-break: break-word;
    }

    .ant-select {
      width: 100%;
      height: 40px !important;
    }

    .top-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-flow: row wrap;
      min-height: 50px;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid master.$grey;
      gap: 20px;
      .search-bar-container {
        font-size: 15px;
        flex: 1 1;
        min-width: 200px;
      }

      .manage-btn {
        border-radius: 50px;
        white-space: nowrap;
        flex: 0 0 fit-content;
      }
    }

    /* Container for both carets and titles */
    .user-list-container,
    .add-users-list {
      display: grid;
      column-gap: 20px;
    }
    .user-list-container {
      row-gap: 15px;
      grid-template-columns: [checkbox] max-content [profilepic] max-content [userdetail] 1fr [role] max-content;
      grid-template-areas: "checkbox profilepic userdetail role";
      @media (max-width: $user-list-mobile-break) {
        grid-template-columns: [checkbox] max-content [profilepic] 0 [userdetail] 1fr [role] max-content;
        grid-template-areas: "checkbox userdetail userdetail userdetail" ". role role role";
      }
      @media (max-width: $user-list-toosmall-break) {
        grid-template-areas: "userdetail userdetail userdetail userdetail" "role role role role";
      }
      .shared-list-header,
      .shared-list-container {
        padding: 5px 0;
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / -1;
        @media (max-width: $user-list-mobile-break) {
          row-gap: 0;
          padding: 5px 0;
          border-bottom: 1px solid master.$border-color;
          .profile-pic {
            display: none;
          }
          .custom-dropdown {
            height: 30px;
            margin: 10px 0 0 0;
            .selected-box{
              padding: 4px 15px;
            }
          }
        }
        @media (max-width: $user-list-toosmall-break) {
          .check-box {
            display: none;
          }
          .header.role {
            display: none;
          }
        }
      }
      .shared-list-header {
        @media (max-width: $user-list-mobile-break) {
          .role {
            // Forces 'Role' sorter to top row.
            grid-column: this-is-not-set;
            text-align: right;
          }
        }
      }
    }
    .add-users-list {
      row-gap: 25px;
      max-height: 250px;
      grid-template-columns: [profilepic] max-content [userdetail] 1fr [role] max-content [action] max-content;
      grid-template-areas: "profilepic userdetail role action";
      &.scrollbars {
        overflow-y: auto;
        padding-right: 10px;
      }
      .data-row {
        display: grid;
        border: 1px solid master.$border-color;
        border-radius: 6px;
        grid-template-columns: subgrid;
        grid-column: 1 / -1;
        padding: 10px 15px;
        align-items: center;
        justify-content: center;
        &.expanded {
          padding-bottom: 35px;
          @include master.media-smalldevice {
            padding-bottom: 64px;
          }
        }
        h4 {
          font-size: 16px;
        }
        p {
          font-size: 15px;
        }
        .access-error {
          position: absolute;
          left: 15px;
          bottom: 10px;
          font-size: 13px;
          color: master.$darkest-grey;
          a {
            color: master.$theme-color;
          }
          @include master.media-smalldevice {
            font-size: 11px;
            margin-top: 5px;
            left: 10px;
          }
        }
        @include master.media-smalldevice {
          h4 {
            font-size: 14px;
          }
          p {
            font-size: 13px;
          }
          .action {
            position: unset;
          }
          .remove-btn {
            position: absolute;
            right: 5px;
            top: 5px;
          }
        }
      }
      @media (max-width: master.$small-device) {
        grid-template-columns: [userdetail] 1fr [role] max-content [action] max-content;
        grid-template-areas: "userdetail userdetail role action";
        .profile-pic {
          display: none;
        }
        .data-row {
          padding: 10px;
          padding-right: 20px;
        }
        .custom-dropdown {
          margin: 0;
          margin-top: 10px;
        }
      }
      @media (max-width: $user-list-mobile-break) {
        grid-template-areas: "userdetail userdetail userdetail userdetail" "role role role action";
        margin: 10px 0 0 0;
      }
    }

    .header {
      font-weight: bold;
    }
    .check-box {
      grid-column: checkbox;
      display: flex;
      align-items: center;
      .ant-checkbox-wrapper {
        .ant-checkbox-inner {
          width: 21px;
          height: 21px;
        }
      }
    }
    .profile-pic {
      @include master.flex-center();
      grid-column: profilepic;
      text-transform: uppercase;
      width: 36px;
      height: 36px;
      background: master.$theme-color;
    }

    .user-detail {
      font-size: 1rem;
      grid-column: userdetail;
      &.header {
        grid-column: profilepic / span 2;
        cursor: pointer;
        white-space: nowrap;
      }
      &.user-detail-button {
        cursor: pointer;
        background: transparent;
        border: none;
        text-align: left;
        h4,
        p {
          margin: 0;
          line-height: 20px;
          word-break: break-word;
        }
        @include master.media-smalldevice{
          h4{
            font-size: 14px;
          }
          p{
            font-size: 13px;
          }
        }
      }
    }

    /* Individual containers for caret and title */
    .role {
      grid-column: role;
      min-width: 126px;
      max-width: 140px;
      &.header {
        font-size: 1rem;
        cursor: pointer;
        text-align: center;
        white-space: nowrap;
        @media (max-width: $user-list-mobile-break) {
          text-align: left;
        }
      }
      &.action-button {
        @include master.flex-center();
        background: master.$light-grey;
        font-family: master.$font-arial-bold;
        border-radius: 50px;
        height: 40px;
        max-height: 40px;
        &.owner-tag {
          button {
            justify-content: center;
            span {
              text-align: center;
            }
          }
          svg {
            display: none;
          }
          /* Stop empty dropdown from displaying */
          .selected-box{
            background: none;
          }
          .dropdown-options{
            display: none;
          }
        }
      }

      .selected-box {
        background: master.$light-grey;
        padding-bottom: var(--action-button-padding);
        padding-top: var(--action-button-padding);
        border: none;
        .placeholder {
          text-overflow: initial;
        }
        &:hover {
          background: master.$grey;
        }
      }
      .dropdown-options {
        background: master.$light-grey;
        border: none;
        button:hover {
          background: master.$grey !important;
        }
      }
    }

    .action {
      grid-column: action;
      button {
        cursor: pointer;
        background-color: master.$white;
        color: #878484;
        border: none;
        font-size: 20px;
        padding: calc(var(--action-button-padding) - 3px);
        &:hover {
          color: master.$darkest-grey;
        }
      }
    }

    .user-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      @media (max-width: $user-list-toosmall-break) {
        .ant-pagination-item:not(.ant-pagination-item-active) {
          display: none;
        }
      }
      .pagination-action-box {
        @include master.flex-start();
        height: 100%;
        gap: 20px;
        svg {
          color: master.$theme-color;
        }
      }
    }
    .welcome-toggle {
      margin-bottom: 10px;
      span {
        font-size: 15px;
      }
      .ant-checkbox-inner {
        width: 24px;
        height: 24px;
        &::after {
          inset-inline-start: 30%;
        }
      }
    }
    .action-box {
      margin-top: 50px;
      margin-bottom: 10px;
      gap: 10px;
      @include master.flex-between();
      .copy-actions {
        display: flex;
        flex-flow: row wrap;
        gap: 10px;
        button {
          padding: 12px 20px;
          font-size: 14px;
          @include master.media-smalldevice {
            font-size: 12px;
            padding: 7px 12px;
          }
        }
      }
      button {
        font-size: 15px;
        min-width: 140px;
        border-radius: 30px;
        padding: 12px 25px;
        font-family: master.$font-arial-bold;

        svg {
          margin-right: 5px;
          min-width: 20px;
        }

        &.back-btn {
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }
      @include master.media-smalldevice {
        margin-top: 15px;
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 15px;
      }
    }
    @include master.media-smalldevice {
      padding: 30px 20px 40px;
    }
    .disclaimer{
      font-size: 15px;
      @include master.media-smalldevice {
        font-size: 13px;
      }
    }
  }
}

.remove-access {
  margin: 30px 0 !important;
  max-width: calc(100% - 60px);
  .ant-modal-content {
    padding: 50px 55px 60px;

    .ant-modal-close {
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      &-icon {
        font-size: 18px;
      }
    }

    .title {
      font-size: 32px;
      padding-left: 20px;
      font-family: master.$font-arial-bold;
      width: calc(100% - 130px);
      margin-bottom: 15px;
      &::before {
        content: "";
        position: absolute;
        width: 8px;
        height: calc(100% - 14px);
        top: 7px;
        left: 0;
        background: master.$theme-color-primary;
      }
    }

    p {
      font-size: 17px;
      margin-bottom: 20px;
      b {
        font-family: master.$font-arial-bold;
      }
    }

    .action-box {
      margin-top: 50px;
      @include master.flex-between();

      button {
        font-size: 16px;
        min-width: 120px;
        border-radius: 30px;
        padding: 12px 30px;
        font-family: master.$font-arial-bold;

        &.back-btn {
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }
    }
  }
}
